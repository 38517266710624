.react-tel-input .form-control {
    padding: 8.5px 14px 8.5px 55px;
    border: 1px solid grey;
    width: 100%;
    font-size: 12px;
}

.react-tel-input .form-control:focus {
    border: 1px solid #014876;
    box-shadow: 0 0 0 1px #014876;
}

.react-tel-input .special-label {
    top: 1px;
    left: -5px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0px 7px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.7857142857142857rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;
    transform: translate(14px, -9px) scale(0.75);
}