.swiper-button-prev {
  left: 2rem !important;
}

.swiper-button-next {
  right: 2rem !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff !important;
}

.swiper-pagination-bullets {
  bottom: 3rem !important;
}

.swiper-pagination-bullet {
  -webkit-transition: all 0.5s linear !important;
  -o-transition: all 0.5s linear !important;
  transition: all 0.5s linear !important;
}

.swiper-pagination-bullet-active {
  width: 70px !important;
  background-color: #d9d9d9 !important;
}
