
  .main {
    max-width: 1200px;
  }
  
  .cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .cards_item {
    display: flex;
    padding: 1rem;
    width: 300px;
  }

  @media only screen and (max-width: 600px) {
    .cards_item {
        width: 250px;
      }
  }
  
  .card_image {
    position: relative;
    max-height: 250px;
    min-height: 250px;
    border-bottom: 1px solid #014876;
  }
  
  .card_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* .card_price {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 0.25rem;
    background-color: #014876;
    font-size: 18px;
    font-weight: 700;
  }
   */
  .card_price span {
    font-size: 12px;
    margin-top: -2px;
  }
  
  .note {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 4px 8px;
    border-radius: 0.25rem;
    background-color: #014876;
    font-size: 14px;
    font-weight: 700;
  }
  
  /* @media (min-width: 40rem) {
    .cards_item {
      width: 200px;
    }
  }
  
  @media (min-width: 56rem) {
    .cards_item {
        width: 300px;
    }
  } */
  
  .card {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .card_content {
    position: relative;
    padding: 16px 12px 32px 24px;
    margin: 16px 8px 8px 0;
    max-height: 290px;
    overflow-y: scroll;
  }
  
  .card_content::-webkit-scrollbar {
    width: 8px;
  }
  
  .card_content::-webkit-scrollbar-track {
    box-shadow: 0;
    border-radius: 0;
  }
  
  .card_content::-webkit-scrollbar-thumb {
    background: #014876;
    border-radius: 15px;
  }
  
  .card_title {
    position: relative;
    margin: 0 0 24px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  
  .card_title::after {
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #014876;
    content: "";
  }
  
  hr {
    margin: 24px auto;
    width: 50px;
    border-top: 2px solid #014876;
  }
  
  .card_text p {
    margin: 0 0 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .card_text p:last-child {
    margin: 0;
  }
  